<template>
      <CommonHeader />

         <!--*************alert red-->
    <div :class="alertClass" id="hidemessage" v-if="message">
        <p class="txt">{{ alertMessage }}</p>
        <i class="fa fa-times close_msg" aria-hidden="true" @click="close_msg()"></i>
    </div>
    <!--*************alert red-->
    <!--*************alert green-->
    <div :class="alertClassg" id="hidemessageg" v-if="messageg">
        <p class="txt">{{ alertMessageg }}</p>
        <i class="fa fa-times close_msgg" aria-hidden="true" @click="close_msgg()"></i>
    </div>
    <!--*************alert green-->


    <div id="main-content">
        <div id="page-container">
            <!--**************************common**************-->
            <h1 class="hed" style="margin-left: 0%;">IMS - GST Input Tax Credit

            </h1>

            <div class="clear"></div>
          

            <div class="filter">
                <ul>
                    <li style="width: 24%;">
                        <datepicker v-model="date" inputFormat="dd-MM-yyyy" id="start_date" class="cal1"></datepicker>
                        <span style="    float: left;
        width: 10%;
        text-align: center;
        margin-top: 2%;
        margin-left: -4px;">To</span>
                        <datepicker v-model="date2" inputFormat="dd-MM-yyyy" id="end_date" class="cal1"></datepicker>

                    </li>
                    <li>
                        <button type="button" value="" @click="get_ims_data()"
                            class="btn btn-success orange_Color">Submit <img src="https://instabill.co/public/front/images/loader.gif" v-if="loader4" class="load_show2"  style="width: 15px"></button>
                    </li>

                    <li>
                        <div class="btn-group btn-group_map">
                <button type="button" class="btn  btn-rounded active" @click="MapUnmapCheck('0')" id="first"
                                style="background: linear-gradient(85deg, rgb(246, 106, 89), rgb(255, 174, 0)); color: rgb(255, 255, 255); border: 1px solid rgb(246, 106, 89);">Unsync</button>
                            <button type="button" class="btn btn-rounded" @click="MapUnmapCheck('1')" id="second"
                                style="background-color:#ffffff;color:#ffae00; border:1px solid #f66a59;">Sync</button>
                        </div>
                    </li>
                

                    <!-- <li style="float: right;
        margin-right: 10px;">
                        <div class="search searcha" style="">
                          
                            <input type="text" placeholder="Search.." @keyup="performSearch(this)" v-model="search">
                            <button class="new_search_button">
                                <i class="fa fa-search "
                                    style="background:linear-gradient(85deg, #f66a59, #ffae00); padding:10px;color:white;border-radius:10px;"></i></button>
                        </div>
                    </li> -->
                    <!-- <li>
                        <button class="d_img"><img src="https://instabill.in/public/images/pdf.png"></button>
                        <button class="d_img"><img src="https://instabill.in/public/images/xls.png"></button>
                    </li> -->
                    <li>
                        <div class="file-upload" v-on:click="showImsSidebar">
                            <label for="upload" class="file-upload__label">Fetch IMS Data</label>
                        </div>
                    </li>
                    <li v-if="showSyncToPurchase" style="height: 39px;"> 
                        <button id="sync_btn" class="deleteall" v-on:click="sync_data_ims"  style="border: 1px solid #fff;margin-top: 0px;margin-top: 0px;">Sync to Purchase <img src="https://instabill.co/public/front/images/loader.gif" class="load_show2" v-if="loader1" style="width: 15px"> </button> 
                    </li>

                </ul>
                <div class="search search_client">
                    <input id="myInput" type="text" placeholder="Search.."><button style="padding: 0px; border: 0px; border-radius: 10px; margin-left: 5px;"><i class="fa fa-search" aria-hidden="true"></i></button>
                </div>
            </div>

            <table class="list-box" style="width: 99% !important;">
                <tr class="list">
                    <th><input type="checkbox" v-model="select_all" @click="select" class="selectall"></th>
                  
                    <th>Date</th>
                    <th>Invoice Number</th>
                    <th>GSTIN</th>
                  
                    <th style="text-align:right;">Invoice Total</th>
                </tr>
                <tbody  v-for="(item, index) in getListItems" :key="item.ims_id">
                    
                    <tr >
                        <td > <input type="checkbox" v-model="checkboxs" :value="item.ims_id"
                                @change="checkCheckboxselection" class="selectbox"  v-if="item.issync == 0"></td>
                        <td> {{ item.idt }}</td>
                        <td>{{ item.inum }}</td>
                        <td>{{ item.stin }}</td>
                        <td style="text-align:right">{{ item.val }}<i :data-target="'#data'+index" class="fa fa-chevron-down accordion-toggle" aria-hidden="true" data-toggle="collapse"  ></i></td>
                    </tr>

                    <tr>
                        <td colspan="6">
                            <div  :id="'data'+index" class="box-group accordian-body dev-info collapse" style="">
                                <div class="left_val">
                                    <!-- <p><b>Party Name</b> STATE BANK OF INDIA</p> -->
                                    <p><span>GSTIN</span> {{ item.stin }}</p>
                                </div>
                                <div class="right_val">
                                    <p><b>Taxable Value</b>{{ item.txval }}</p>
                                    <p><span>CGST</span> {{ item.camt }}</p>
                                    <p><span>SGST</span> {{ item.samt }}</p>
                                    <p><span>IGST</span> {{ item.iamt }}</p>
                                    <p><span>Cess</span> {{ item.cess }}</p>
                                    <p><b>Total</b>{{ item.val }}</p>
                                </div>
                            </div>
                        </td>
                    </tr>
                
                </tbody>
            </table>
        </div>
    </div>



    <div v-if="gstDetailSection" id="gstr2b" class="setting gst-side" style="display: block;">
        <div style="margin-bottom:20px; " class="">
        <div class="search" align="" style="color:#cccc;float:left;margin-bottom:10px; margin-left:30px;margin-top:7px;">
            <div class="add-gst"><h3>Fetch IMS Data</h3>
                <div style="clear:both"></div>
             <p style="color: #000;font-size: 15px;">Enter your GST credentials for Instabill to <br>fetch details from the GST portal.</p>
            </div>
            <form v-on:submit.prevent="checkGstNSubmit">   
            <div class="bar_S">
         
            <select class="form-control" required v-model="section"  style="    border: 1px solid #ccc !important;
    padding: 7px;
    border-radius: 5px;
    margin-bottom: 5%;
    width: 85%!important;
    box-sizing: border-box;
    border-radius: 5px !important;">
            <option value="">Select Section</option>
            <option value="B2B">B2B</option>
            <option value="B2BA">B2BA</option>
            <option value="B2BCN">B2BCN</option>
            <option value="B2BCNA">B2BCNA</option>
            <option value="ECOM">ECOM</option>
            <option value="ECOMA">ECOMA</option>
            </select>
      

            <div class="clear"></div>
             </div> 
             
             
             <div class="gstIn" style="">
             
            
          <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="fname">GSTIN*</label><br>
          <input style="     border: 1px solid #ccc !important;
    padding: 7px;
    border-radius: 5px;
    margin-bottom: 5%;
    width: 85%;
    box-sizing: border-box;
    border-radius: 5px !important;" type="text" id="gstin" v-model="gstin" required  maxlength="15" name="gstin" placeholder="15 digits GST Number" ><br>
        
             </div>
             
             
             <div class="gst-user">
           
                  <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="username">GST Username*</label><br>
                  <input  style="    border: 1px solid #ccc !important;
    padding: 7px;
    border-radius: 5px;
    margin-bottom: 5%;
    width: 85%;
    box-sizing: border-box;
    border-radius: 5px !important;" type="text"  v-model="gstUsername" placeholder="GSTN login username" name="username" required="">
                  <button type="submit">Get OTP</button>
                </div>
           
            </form>

            <div v-if="gstOtpSection">
                <form v-on:submit.prevent="validateGstOtp">
                    <div class="otp_Section">
                        <p style="font-size: 12px;font-family: sans-serif;margin-top: 10px;">Enter the One Time Password
                            (OTP) Received on your mobile number registered with the GST portal.</p>
                        <div class="in_p">
                            <label>OTP*</label>
                            <br>
                            <input type="text" v-model="userOtp" maxlength="6" placeholder="Enter OTP" required="" style="width:85%!important;">
                        </div>
                        <u role="button" @click="checkGstNSubmit"
                            style="font-size: 12px;font-family: sans-serif;margin-top: 10px;color: gray;">Resend OTP</u>
                        <div class="ft">
                            <button @click="closeImsSidebar" class="btna1"
                                style="background: #eee;border: 1px solid #ffffff;color: #000;">Cancel</button>
                            <button type="submit" class="btna1">Verify OTP <img src="https://instabill.co/public/front/images/loader.gif" class="load_show" v-if="loader"
                                style="width: 15px;" /></button>
                        </div>
                    </div>
                </form>
            </div>
          </div>
        <button  v-on:click="closeImsSidebar" type="button" class="close" id="setting_close" data-dismiss="alert" style="    top: 0;
            position: absolute;
            top: 10px;
            right: 14px;
            font-size: 35px;
            line-height: 22px;"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </div>
        </div>

 

</template>


<script>
import CommonHeader from './CommonHeader.vue';
import datepicker from 'vue3-datepicker';
import axios from 'axios';
import { postApi } from "../assets/js/helper.js";
import $ from 'jquery';
export default {
    name: 'ImsData',
    components: {
        datepicker,
        CommonHeader
    },
    created() {
        const reloaded = localStorage.getItem('reloaded2');
        if (reloaded !== 'true') {
            localStorage.setItem('reloaded2', 'true');
            location.reload();
        }
        this.userId = this.$route.params.user_id;
        this.companyId = this.$route.params.company_id;
        this.getList();
    },
    data() {
        return {
            alertClass: "",
            alertClassg: "",
            alertMessageg:"",
            alertMessage:"",
            message: false,
            messageg: false,
            date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            date2: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
            userId: null,
            companyId: null,
            gstin: "",
            gstUsername: "",
            inputGstNumber: '',
            inputGstUsername: '',
            getListItems: [],
            section:"",
            gstDetailSection: false,
            gstOtpSection: false,
            userOtp:"",
            loader:false,
            select_all: false,
            checkboxs: [],
            showSyncToPurchase:false,
            search:"",
            checkMapUnmap: 0,
            loader1:false,
            loader4:false,
        }
    },
    methods:{
        async getList() {
            const response = await postApi(
                "/get_data_ims",
                {
                    userId: this.userId,
                    company_id: this.companyId,
                }
            );
            try {
                if (response.errorCode == "0") {
                    console.log(response);
                    this.getListItems = response.data.ims_data;
                    this.inputGstNumber = response.data.gstin;
                    this.inputGstUsername = response.data.gstin_username;
                    this.gstin = this.inputGstNumber;
                    this.gstUsername = this.inputGstUsername;
                }
            } catch (err) {
                alert(err);
            }
        }, greenAlert(messageg) {
            this.alertMessageg = messageg;
            this.alertClassg = "error_msgg";
            this.messageg = true;
            setTimeout(() => this.messageg = '', 3000);
            // this.message = '';
        }, 
        redAlert(message) {
            this.alertMessage = message;
            this.alertClass = "error_msg";
            this.message = true;
            setTimeout(() => this.message = '', 3000);
            // this.message = '';
        },
        showImsSidebar(){
this.gstDetailSection=true;
this.gstOtpSection= false;
this.section="";
},
closeImsSidebar(){
this.gstDetailSection=false;
this.gstOtpSection= false;
this.section="";
},
        checkGstNSubmit() {
            if (!this.gstin) {
                this.redAlert('Please Mention Gst Number !');
                return false;
            } else if (this.gstin.length != 15) {
                this.redAlert('Please Mention Valid Gst Number !');
                return false;
            }
            if (!this.gstUsername) {
                this.redAlert('Please Mention Username !');
                return false;
            }
            let formData = new FormData();
            formData.append('gstin', this.gstin);
            formData.append('username', this.gstUsername);
            axios.post("https://instabill.co/api/send_otp_gstr2a", formData, {
            }).then((response) => {
                // console.log(response);  
                if (response.data.errorCode == "0") {
                    this.greenAlert('Otp Send Successfully !');
                    setTimeout(() => this.message = false, 3000);
                    this.gstOtpSection = true;
                    // alert("Otp Send Successfully !");
                } else {
                    this.redAlert(response.data.errorMsg);
                    setTimeout(() => this.messageg = false, 3000);
                }
            }).catch((error) => {
                alert(error);
            });
        },

        validateGstOtp() {
            this.loader=true;
            if (!this.userOtp) {
                this.redAlert('Please Enter Otp!');
                return false;
            } else if (this.userOtp.length != 6) {
                this.redAlert('Invalid Otp !');
                return false;
            }
            let formData = new FormData();
            formData.append('userId', this.userId);
            formData.append('company_id', this.companyId);
            formData.append('otp', this.userOtp);
            formData.append('section', this.section);
            formData.append('gstin', this.gstin);
            formData.append('username', this.gstUsername);
            axios.post("https://instabill.co/api/verify_otp_ims", formData, {
            }).then((response) => {
                console.log(response);
                if (response.data.errorCode == "0") {
                    this.greenAlert('Data Fetch Successfully');
                    setTimeout(() => this.message = false, 3000);
                    this.gstModal = false;
                    this.gstDetailSection = false;
                    this.gstOtpSection = false;
                   
                    this.getList();
                    this.gstDetailSection=false;
this.gstOtpSection= false;
this.section="";
this.userOtp="";
this.loader=false;
                } else {
                    this.loader=false;
                    alert(response.data.errorMsg);
                    this.redAlert('Something Went Wrong !');
                    setTimeout(() => this.messageg = false, 3000);
                }
            }).catch((error) => {
                alert(error);
            });
        }, 
        select() {
            this.checkboxs = [];

            if (!this.select_all) {
                    for (let i in this.getListItems) {
                        this.checkboxs.push(this.getListItems[i].ims_id );
                    }
                    this.showSyncToPurchase = true;
                } else {
                    this.showSyncToPurchase = false;
                }

            // if (this.search != "") {
            //     if (!this.select_all) {

            //         let result = this.getListItems
            //         if (this.search) {
            //             result = result.filter(event =>
            //                 event.REMARKS.toLowerCase().includes(this.search.toLowerCase())
            //             )
            //         }

            //         for (let i in result) {
            //             this.checkboxs.push(result[i].ims_id);
            //         }
            //         this.showSyncToPurchase = true;
            //     } else {
            //         this.showSyncToPurchase = false;
            //     }

            // } else {
            //     if (!this.select_all) {
            //         for (let i in this.userAccountStatement) {
            //             this.checkboxs.push(this.userAccountStatement[i].id);
            //         }
            //         this.showSyncToPurchase = true;
            //     } else {
            //         this.showSyncToPurchase = false;
            //     }
            // }

        },
        checkCheckboxselection() {
            if (this.checkboxs.length == 0) {
                this.showSyncToPurchase = false;
            } else {
                //Show Button Map 
                this.showSyncToPurchase = true;
            }

        },
        performSearch() {
            var value = (this.search).toLowerCase();

            $("table tr").each(function (index) {
                if (index !== 0) {
                    var id = $(this).children().text().toLowerCase()
                    if (id.indexOf(value) < 0) {
                        $(this).hide();
                    } else {
                        $(this).show();
                    }
                }
            });
        },
        MapUnmapCheck(checkMapUnmapid) {

this.checkMapUnmap = checkMapUnmapid;
if (checkMapUnmapid == "0") {
    this.get_ims_data3();
} else {
    this.get_ims_data2();
}

},
        async get_ims_data() {
            this.select_all = false;
            this.loader4=true;
            const response = await postApi(
                "/get_data_ims",
                {
                    start_date: this.date,
                    end_date: this.date2,
                    userId: this.userId,
                    company_id: this.companyId,
                    isSync:"0"
                },
                {}
            );
            try {
                this.loader4=false;
                if (response.errorCode == "0") {
                    console.log(response);
                    this.getListItems = response.data.ims_data;
                    this.inputGstNumber = response.data.gstin;
                    this.inputGstUsername = response.data.gstin_username;
                    this.gstin = this.inputGstNumber;
                    this.gstUsername = this.inputGstUsername;

                }
               else {
                    alert("Data Not Found");
                    this.getListItems = null;
                    this.alertMessage = "Data Not Found";
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 3000);
                    return false;
                }
                return false;
            } catch (err) {
                alert(err);
            }
        },
        async get_ims_data2() {
            this.select_all = false;
            const response = await postApi(
                "/get_data_ims",
                {
                    start_date: this.date,
                    end_date: this.date2,
                    userId: this.userId,
                    company_id: this.companyId,
                    isSync:"1"
                },
                {}
            );
            try {

                if (response.errorCode == "0") {
                    console.log(response);
                    this.getListItems = response.data.ims_data;
                    this.inputGstNumber = response.data.gstin;
                    this.inputGstUsername = response.data.gstin_username;
                    this.gstin = this.inputGstNumber;
                    this.gstUsername = this.inputGstUsername;

                }
               
                else {
                    alert("Data Not Found");
                    this.getListItems = null;
                    this.alertMessage = "Data Not Found";
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 3000);

                    return false;
                  
                }
                return false;
            } catch (err) {
                alert(err);
            }
        },
        async get_ims_data3() {
            this.select_all = false;
           const response = await postApi(
                "/get_data_ims",
                {
                    start_date: this.date,
                    end_date: this.date2,
                    userId: this.userId,
                    company_id: this.companyId,
                    isSync:"0"
                },
                {}
            );
            try {
               if (response.errorCode == "0") {
                    console.log(response);
                    this.getListItems = response.data.ims_data;
                    this.inputGstNumber = response.data.gstin;
                    this.inputGstUsername = response.data.gstin_username;
                    this.gstin = this.inputGstNumber;
                    this.gstUsername = this.inputGstUsername;

                }
               else {
                    alert("Data Not Found");
                    this.getListItems = null;
                    this.alertMessage = "Data Not Found";
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 3000);
                    return false;
                }
                return false;
            } catch (err) {
                alert(err);
            }
        },

        

  async sync_data_ims() {
    this.loader1=true;
            this.select_all = false;
            const response = await postApi(
                "/sync_data_ims",
                {
                   userId: this.userId,
                   company_id: this.companyId,
                    ids:this.checkboxs
                },
                {}
            );
            try {
this.showSyncToPurchase=false;
                if (response.errorCode == "0") {
                 this.greenAlert('All Entry Sync to Purchase');
                    setTimeout(() => this.message = false, 3000);
                this.loader1=false;   
                this.getList();
                }else{
                    this.loader1=false;
                    this.redAlert('Something Went Wrong!!');
                    setTimeout(() => this.message = false, 3000);
                }
                return false;
            } catch (err) {
                alert(err);
            }
        },
        close_msg() {
            // alert();
            this.message = false;
        },
        close_msgg() {
            // alert();
            this.messageg = false;
        },



    }

}

</script>

<style scoped>
div#data0 {
    background: #fdfdfd;
    display: flex
;
    justify-content: space-between;
}
i.fa.fa-chevron-down.accordion-toggle {
    background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
    color: #fff;
    font-size: 8px;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    line-height: 4px;
    padding: 6px 0px;
    cursor: pointer;
    text-align: center;
    margin: auto;
    margin-left: 5px;
}
label.file-upload__label {
    margin: 0;
    color: #fff;
    background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
    border-color: #fea40d;
    border-radius: 5px !important;
    border: 0px !important;
    height: 38px;
    margin-right: 3px !important;
    padding: 6% 5px !important;
    cursor:pointer;
}


button#sync_btn {
    color: #fff;
    background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
    border-color: #fea40d;
    border-radius: 5px !important;
    border: 0px !important;
    height: 38px;
    margin-right: 3px !important;
    padding: 1% 5px !important;
}
.uploader_purchase #response1.hidden1 {
    display: none;
}
button.d_img {
    border: 0px;
    cursor: pointer;
    background: transparent;
}
div#mltpl-btn {
    position: absolute;
    width: 10%;
    background: #fff;
    border-radius: 5px !important;
}

button#first {
    background: linear-gradient(85deg, rgb(246, 106, 89), rgb(255, 174, 0));

    border: 1px solid rgb(246, 106, 89);
    background: #fff;
    border: 1px solid #f66a59;
}

div#mltpl-btn div input {
    background: transparent;
    color: #000;
    font-size: 13px;
    border: 0px !important;
    border-bottom: 1px solid #e4dddd !important;
    width: 100%;
    padding: 6%;
}

div#start1 i {
    background: transparent;
    color: #565656;
}

li .v3dp__datepicker input {
    height: 38px !important;
    border-radius: 5px !important;
    text-align: center !important;
}

table.list-box {
    width: 99%;
    margin-left: 0%;
}

.card.card-statistics {
    background: linear-gradient(85deg, #f66a59, #ffae00);
    color: #ffffff;
}

button.unsync {
    background: #FF5E6D;
    color: #fff;
    border: 0px;
    border-radius: 18px;
    padding: 4px 11px;
    font-size: 13px;
}

.selected {
    border-radius: 5px;
    background: #fda110 !important;
}

.banktab {
    float: left;
    width: 35% !important;
    margin-right: 2%;
}

button.sync {
    background: #04b76b;
    color: #fff;
    border: 0px;
    border-radius: 18px;
    padding: 4px 11px;
    font-size: 13px;
}

.banking_right {
    width: 44%;
    float: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.bank_Sec {
    margin-top: 2%;
    overflow: hidden;
}

label.badge.badge-outline-success.badge-pill {
    color: #fff;
    border: 1px solid #fff;
    padding: .5rem .75rem;
    margin-right: 1%;
}

.p_banking_add.p_banking_add_p {
    width: 38%;
    float: left;
    min-width: 38% !important;
    padding-top: 1% !important;
    border: 1px solid #F5A623;
    border-radius: 50px;
    padding: 6px 2% !important;
    text-align: center;
}

img.img-fluid.aa_p {
    position: absolute;
    left: 4%;
    width: 30px;
    height: 30px;
    top: 21%;
}

.p_banking_add.p_banking_add_p {
    width: 24%;
    float: left;
    min-width: 38% !important;
    padding-top: 8px !important;
    padding-bottom: 9px !important;
    position: relative;
    text-align: right;
}

.filter ul li {
    list-style: none;
    float: left;
    margin-right: 1%;
}

.filter ul {
    padding: 0px;
}

.orange_Color {
    color: #fff;
    background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
    border-color: #fea40d;
    border-radius: 5px !important;
    border: 0px !important;
    height: 38px;
    margin-right: 3px !important;
    padding: 1% 4px !important;
}

button.btn.btn-success.orange_Color {
    border-radius: 5px !important;
    padding: -0px 9px !important;
    font-size: 15px;
}

.searcha {
    display: inline-block !important;
    height: calc(2.25rem + 2px) !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    vertical-align: middle !important;
    background: #fff !important;
    background-size: 8px 10px !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: all ease-out 2s !important;
    margin: 0px;
    margin-right: 15px;
    position: relative;
    width: 178px !important;
}

.search {
    width: 100% !important;
}

.searcha button {
    position: absolute;
    right: 0;
    top: 0px;
    border-radius: 0px !important;
    border: 0px;
    height: 100%;
    padding: 0px;
}

.searcha button i {
    border-radius: 0px !important;
}

.searcha input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0px;
    padding: 1% 2%;
}

button#btnGroupDrop1 {
    color: #fff;
    background: linear-gradient(85deg, #f66a59, #ffae00);
}

.dropdown-menu.show a {
    padding: 0% 4%;
}

.dropdown-menu.drop-add-css1.show {
    right: 0px !important;
    left: -400px !important;
    top: 98% !important;
    overflow-y: scroll;
    min-height: 218px;
    width: 400px;
    transform: none !important;
    max-height: 159px !important;
}

span.bank_N {
    color: #fc991b;
}

.banking_right a#setting_side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

button#btnGroupDrop1 {
    margin-right: 5px;
    border-radius: 50px !important;
}

.bank_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

button#btnGroupDrop1:hover {
    color: #fff;
}

label.badge.badge-outline-success.badge-pill {
    color: #fff;
    border: 1px solid #fff;
    padding: 0.5rem 0.75rem;
    margin-right: 1%;
}

.btn-group.btn-group_map {
    border-radius: 50px;
}

button#first {
    border-radius: 50px 0px 0px 50px !important;
}

.v3dp__datepicker {
    display: inline;
    float: left;
    width: 43% !important;
    margin-top: 0px;
    height: 38px;
    margin-right: 2%;
}

span.edit_s {
    color: #fff;
    border: 1px solid #fff;
    padding: 4px 7px;
    margin-right: 1%;
    border-radius: 50px;
    margin-left: 2%;
    cursor: pointer;
}

.add-gst h3 {
    color: #f97d40;
    font-size: 19px;
    text-transform: uppercase;
}

p.txt {
    width: 91%;
    float: left;
    margin: 0;
}

div#setting2 input {
    border: 1px solid #ccc !important;
    width: 100%;
    padding: 2%;
    border-radius: 5px;
}

.gstIn-corporate {
    margin: 9% 0%;
    margin-bottom: 8% !important;
}

div#setting2 textarea {
    border: 1px solid #ccc !important;
    width: 100%;
    padding: 2%;
    border-radius: 5px;
}

#hidemessage {
    position: fixed;
    top: 0%;
    z-index: 999;
    width: 20%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid #db0014;
    transition: 1s;
    color: red;
}

#hidemessageg {
    position: fixed;
    top: 0%;
    z-index: 999;
    width: 25%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid #00db92;
    transition: 1s;
    color: #00db92;
}

div#opening_balance {
    display: block;
    float: right;
    z-index: 9999;
    position: fixed;
    right: 0;
    top: 0px;
    background: white;
    /* color: white; */
    width: 410px;
    height: 100%;
    overflow-y: auto;
    transition: right 1s linear;
    border: 1px solid #d4d7dc;
    border-right: none;
    border-top: none;
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
    padding: 1% 2%;
}

.gstIn label {
    font-size: 16px;
}

button#second {
    border-radius: 0px 50px 50px 0px !important;
}

button.verify-otp {
    background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
    border-color: #fea40d;
    color: #fff;
    border-radius: 5px !important;
}

button#opening_balances1 {
    background: #4c4c4d !important;
    border: 0px;
    border-radius: 5px !important;
}

button.btn.btn-success.account_submit {
    border-radius: 5px !important;
}

.clear {
    clear: both
}

button.ai1 {
    background: #fff;
    color: #000;
    border: 0px;
    padding: 7px 20px;
    border-radius: 30px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    border: 1px solid #ffae00;
}

svg.blinking {
    margin-right: 7px;
}


svg.blinking {
    -webkit-animation: 1s blink ease infinite;
    -moz-animation: 1s blink ease infinite;
    -ms-animation: 1s blink ease infinite;
    -o-animation: 1s blink ease infinite;
    animation: 1s blink ease infinite;
}

@keyframes blink {

    from,
    to {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@-moz-keyframes blink {

    from,
    to {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@-webkit-keyframes blink {

    from,
    to {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@-ms-keyframes blink {

    from,
    to {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@-ms-keyframes blink {

    from,
    to {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}
button.btna1 {
    padding: 6px 14px;
    font-size: 15px;
    width: auto;
    text-transform: capitalize;
}

.ft {
    width: 85%;
    margin-top: 5%;
    margin-bottom: 2%;
}

button#isedit_close1 {
    display: block;
    width: auto;
    font-weight: 600;
    top: 22px !important;
}

button#isedit_close1 span {
    font-weight: 700 !important;
}
button#setting_close span {
    font-size: 26px;
    font-weight: 800 !important;
}
div#gstr2b {
    float: right;
    z-index: 99999999999999;
    position: fixed;
    right: 0;
    top: 0px;
    background: white;
    /* color: white; */
    width: 350px;
    height: 100%;
    overflow-y: auto;
    transition: right 1s linear;
    /* padding: 30px; */
    border: 1px solid #d4d7dc;
    border-right: none;
    border-top: none;
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
    overflow-x: hidden;
    display:none;
}

.add-gst h3 {
    color: #f97d40;
    font-size: 23px;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.bar_S {
    overflow: hidden;
}
.bar_S div {
    width: 37%;
    float: left;
}
.bar_S div select {
    border: 1px solid gray;
    padding: 7px;
    margin-left: 2px;
    border-radius:5px;
}
.resend-otp {
    margin-top: 40px;
    border-top: 2px solid #80808029;
    padding-top: 20px;
}
.btn-devide button {
    padding: 8px 50px;
    border-radius: 36px;
    border: 1px solid gray;
    background: none;
    text-transform: capitalize;
    cursor: pointer;
   float:left;
}.gst-user button {
    background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
    color: #fff;
    border: 0px;
    padding: 7px 3% 7px;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 5px;
    display: flex
;    width: auto;
    align-items: center;
    justify-content: center;
}
button.verify-otp {
    background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
    border-color: #fea40d;
    color: #fff;
    padding: 8px 34px;
    margin-left:5%;
}.search.search_client {
    width: 23% !important;
    float: right;
    margin: 0px;
}
.btn-devide button {
    padding: 8px 50px;
    border-radius: 36px;
    border: 1px solid gray;
    background: none;
    text-transform: capitalize;
    cursor: pointer;
    float:left;
}
button.resend {
    margin-left: 20px;
    border: none;
    background: no-repeat;
    color: #5c6bc0;
    font-weight:600;
}
.in_p label {
    font-size: 14px;
    color: rgb(0, 0, 0);
    margin-bottom: 5px;
    font-weight: 600;
}
.error-msg {
    display: none;
}
.add-gst.oops-error {
    margin-top:8%;
}

</style>