<template>
    <CommonHeader />

       <!--*************alert red-->
  <div :class="alertClass" id="hidemessage" v-if="message">
      <p class="txt">{{ alertMessage }}</p>
      <i class="fa fa-times close_msg" aria-hidden="true" @click="close_msg()"></i>
  </div>
  <!--*************alert red-->
  <!--*************alert green-->
  <div :class="alertClassg" id="hidemessageg" v-if="messageg">
      <p class="txt">{{ alertMessageg }}</p>
      <i class="fa fa-times close_msgg" aria-hidden="true" @click="close_msgg()"></i>
  </div>
  <!--*************alert green-->


  <div id="main-content">
      <div id="page-container">
          <!--**************************common**************-->
          <h1 class="hed" style="margin-left: 0%;">
          Test

          </h1>

          <div class="clear"></div>
      
         <!-- <ejs-grid :dataSource="localData">


         </ejs-grid> -->
      </div>
  </div>



</template>


<script>
// import Vue from 'vue';
import CommonHeader from './CommonHeader.vue';
// import { GridPlugin } from '@syncfusion/ej2-vue-grids';
// Vue.use(GridPlugin);

export default {
  name: 'GridDatav',
  components: {
      CommonHeader
  },

  // data() {
  //   return {
  //     localData: [
  //       {OrderID: 10248, CustomerID: 'pooja', Price: 32.38},
  //       {OrderID: 10249, CustomerID: 'pooja1', Price: 32.38},
  //       {OrderID: 10250, CustomerID: 'pooja2', Price: 32.38},
  //       {OrderID: 10251, CustomerID: 'pooja3', Price: 32.38},
  //       {OrderID: 10252, CustomerID: 'pooja4', Price: 32.38},
  //     ]
  //   }
  // }


 

}

</script>

<style scoped>
@import url(https://cdn.syncfusion.com/ej2/meterial.css)
</style>